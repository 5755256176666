<template>
  <div class="page14">
    <img class="page14-img" src="../../assets/pages/obj_14.png" alt="">
  </div>
</template>

<script>
export default {
  name: 'page14',
}
</script>

<style scoped lang="scss">
.page14 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .page14-img {
    height: 100%;
  }
}
</style>